import React from 'react'

const BannerLanding = props => (
  <section id="banner" className="style2">
    <div className="inner">
      <header className="major">
        <h1>About Us</h1>
      </header>
    </div>
  </section>
)

export default BannerLanding
